import moment from "moment";
import { getLoadLocations } from "./timeTransform";

export const _findIndexByID = (arr, id) => arr.findIndex((i) => +i.id === +id);

export const _findIndexByChatID = (arr, chat_id) =>
  arr.findIndex((i) => +i.chat_message?.chat_id === +chat_id);

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const sortBy = (arr, key) => {
  // sort arr of objects by key
  if (!arr || !Array.isArray(arr)) return [];
  return arr.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
};

export const sortByNumber = (arr, key) => {
  return arr.sort((a, b) => a - b);
};

export const checkOnNull = (objectElement) => {
  return objectElement !== null ? objectElement : "";
};
export const isArray = (arr) => Array.isArray(arr);

const isSplitLoad = (item, type) => {
  if (!type)
    return (
      item &&
      item.load &&
      item.load.load_drivers &&
      item.load.load_drivers.length > 1
    );
  else return item && item.load_drivers && item.load_drivers.length > 1;
};

const getLoadsPrices = (item, driver_id, isSplit) => {
  if (!isSplit) {
    return item.price;
  } else {
    return item.load_drivers.filter(
      (load_driver) =>
        load_driver.driver_id === driver_id ||
        load_driver.co_driver_id === driver_id
    )[0]?.price;
  }
};

//driver_loads [item.load] => load
export const sortLoadsByPeriod = (
  loadsArray,
  { period_start, period_end },
  filterByIdsArray = "",
  driver_id = ""
) => {
  let loads = [];

  if (!filterByIdsArray) {
    loads = loadsArray.map((item) => {
      let isSplit = isSplitLoad(item, filterByIdsArray);
      let statements = (
        Array.isArray(item.load.statements) ? item.load.statements : []
      ).map((loaditem) => moment(loaditem.batch_date).format("MM/DD"));
      return {
        ...item.load,
        split_load: isSplit,
        price: isSplit ? item.price : item.load.price,
        statements,
        load_locations: item.load.load_locations.sort((a, b) => {
          return +b.is_shipper - +a.is_shipper;
        }),
      };
    });
  } else {
    loads = loadsArray.map((item) => {
      let isSplit = isSplitLoad(item, filterByIdsArray);
      let price = getLoadsPrices(item, driver_id, isSplit);
      let statements = (
        Array.isArray(item.statements) ? item.statements : []
      ).map((loaditem) => moment(loaditem.batch_date).format("MM/DD"));
      return {
        ...item,
        price,
        statements,
        split_load: isSplit,
        load_locations: item.load_locations.sort((a, b) => {
          return +b.is_shipper - +a.is_shipper;
        }),
      };
    });
  }

  let momentStart = moment(period_start, "yyyy-MM-DD").format("X");
  let momentEnd = moment(period_end, "yyyy-MM-DD").format("X");
  let sortedArrayOfLoads = loads.reduce(
    (prevItem, item, index) => {
      let shipper_time = moment(
        item.load_locations[0]?.available_from_date,
        "yyyy-MM-DD"
      ).format("X");
      let consignee_time = moment(
        item.load_locations[item.load_locations.length - 1]?.available_to_date,
        "yyyy-MM-DD"
      ).format("X");
      let idx = "";
      const shipper_load_location = getLoadLocations(
        item?.load_locations,
        "is_shipper"
      );
      const cons_load_location = getLoadLocations(
        item?.load_locations,
        "is_consignee"
      );
      // console.log('shipper', shipper_load_location)
      // console.log('is split', item)
      if (item.split_load) {
        return {
          ...prevItem,
          splitLoads: [
            ...prevItem.splitLoads,
            {
              ...item,
              consignee_time,
              shipper_load_location,
              cons_load_location,
            },
          ],
        };
      }
      if (shipper_time >= momentStart && momentEnd >= consignee_time) {
        for (let i = 0; i <= prevItem.inPeriod.length; i++) {
          if (idx === "") {
            if (i === prevItem.inPeriod.length || !prevItem.inPeriod.length) {
              idx = i;
            } else if (prevItem.inPeriod[i].consignee_time > consignee_time) {
              idx = i;
            }
          } else {
            break;
          }
        }
        return {
          ...prevItem,
          inPeriod: [
            ...prevItem.inPeriod.slice(0, idx),
            {
              ...item,
              consignee_time,
              shipper_load_location,
              cons_load_location,
            },
            ...prevItem.inPeriod.slice(idx),
          ],
        };
      } else {
        for (let i = 0; i <= prevItem.notInPeriod.length; i++) {
          if (idx === "") {
            if (
              i === prevItem.notInPeriod.length ||
              !prevItem.notInPeriod.length
            ) {
              idx = i;
            } else if (
              prevItem.notInPeriod[i].consignee_time > consignee_time
            ) {
              idx = i;
            }
          } else {
            break;
          }
        }
        return {
          ...prevItem,
          notInPeriod: [
            ...prevItem.notInPeriod.slice(0, idx),
            {
              ...item,
              consignee_time,
              shipper_load_location,
              cons_load_location,
            },
            ...prevItem.notInPeriod.slice(idx),
          ],
        };
      }
    },
    { inPeriod: [], notInPeriod: [], splitLoads: [], notSelected: [] }
  );
  return sortedArrayOfLoads;
};

export const accessorialOptions = [
  { name: "Detention", value: "1" },
  { name: "Driver Load", value: "2" },
  { name: "Driver Unload", value: "3" },
  { name: "Escort", value: "4" },
  { name: "Exstra Stop", value: "5" },
  { name: "FSC-MIles", value: "6" },
  { name: "FSC-Percent", value: "7" },
  { name: "Late Delivery", value: "8" },
  { name: "Late Pick Up", value: "9" },
  { name: "Lumper", value: "10" },
  { name: "No Update fee", value: "11" },
  { name: "Out of pocket", value: "12" },
  { name: "Range Change", value: "13" },
  { name: "Redelivery", value: "14" },
  { name: "Rate Powery", value: "15" },
  { name: "Scale ticket", value: "16" },
  { name: "Tarping", value: "17" },
  { name: "TONU", value: "18" },
  { name: "Trailer Repair", value: "19" },
  { name: "Dispatch Control", value: "21" },
  { name: "Quality Control", value: "22" },
  { name: "Other", value: "20" },
];

export const getDriverExpenses = (loadsArray) => {
  let expensesArray = loadsArray.reduce((prevItem, item) => {
    if (item.load_expenses && item.load_expenses.length) {
      return [...prevItem, item.load_expenses];
    } else return prevItem;
  }, []);
  return expensesArray.reduce((prevItem, item) => {
    let newArray = item.map((exp) => ({
      ...exp,
      accesorialName: accessorialOptions[exp.accessorial_id - 1].name,
    }));
    return [...prevItem, ...newArray];
  }, []);
};

export const setFetchedDescCols = (desc_cols) => {
  if (typeof desc_cols === "object") {
    return Object.entries(desc_cols).reduce((prevItem, item, index) => {
      return { ...prevItem, [item[0]]: item[1] ?? "" };
    }, {});
  } else return {};
};
