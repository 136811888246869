import firebase from "firebase/app";
import "firebase/messaging";
import Cookies from "universal-cookie";
import { httpPost } from "./api";

const cookies = new Cookies();

const firebaseConfig = {
  apiKey: "AIzaSyAb0LxDJkTAy6RHOsg5mO1GH-l2peZmaqY",
  authDomain: "kais-notify.firebaseapp.com",
  projectId: "kais-notify",
  storageBucket: "kais-notify.appspot.com",
  messagingSenderId: "1079785148217",
  appId: "1:1079785148217:web:6888bfbedd6e2dc8fa62e8",
  measurementId: "G-MGMGK4XQW4",
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const getToken = (setTokenFound) => {
  return messaging
    .getToken({
      vapidKey:
        "BIVN3W3hKC9pmgLUfqtkqNaps6jTTahdQ6gTxGvaHfzSWJHIrL3HToLkWvCXOb-J0iMtdEd5C2uObJGmabK-WVA",
    })
    .then((currentToken) => {
      if (currentToken) {
        const user_type_id = cookies.get("user_type_id");
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        if (user_type_id.length) {
          let requestOptions = {
            url: "admin/notifications/push",
            data: { device_key: currentToken },
          };
          if (+user_type_id === 1) {
            requestOptions = {
              ...requestOptions,
              url: "drivers/notifications/push",
            };
          }
          httpPost(requestOptions).then(() => console.log("token sent"));
        }
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      // console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
