import {
  _findIndexByChatID,
  _findIndexByID,
  sortBy,
} from "src/utils/arrayOperations";
import * as actions from "../types/chat";

const initialState = {
  drivers: [],
  driverLoads: [],
  sentMessage: "",
  messageSearch: "",

  chatMessLoading: false,
  chatBeingRead: false,
  chatMeta: {},
  messages: [],
  chatRead: {},
};

const chat = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_DRIVERS:
      return {
        ...state,
        drivers: sortBy(action.payload, "unread_count").reverse(),
      };

    case actions.FETCH_DRIVER_LOADS: {
      const { payload, activeDriverId } = action;
      if (activeDriverId && state.drivers?.length) {
        const drivers = state.drivers;
        const firstDriver = drivers[0];
        const idx = drivers.findIndex((d) => d.id === activeDriverId);
        drivers[0] = drivers[idx];
        drivers[idx] = firstDriver;

        return { ...state, driverLoads: payload, activeDriverId, drivers };
      }

      return { ...state, driverLoads: payload };
    }
    case actions.CLEAR_CHAT_LOADS:
      return { ...state, driverLoads: [] };

    case actions.SEND_MESSAGE: {
      let messages = [...state.messages];
      let driverLoads = [...state.driverLoads];
      const currentLoadIndex = _findIndexByChatID(
        driverLoads,
        action.payload?.chat_id
      );

      const isMessageDuplicate = _findIndexByID(messages, action.payload.id);

      if (isMessageDuplicate === -1) {
        messages = [action.payload, ...messages]; // recent first, old last
      }

      if (currentLoadIndex > -1) {
        driverLoads[currentLoadIndex] = {
          ...driverLoads[currentLoadIndex],
          chat_message: {
            ...action.payload,
          },
        };
      }

      return {
        ...state,
        sentMessage: action.payload,
        messages: [...messages],
        driverLoads,
      };
    }

    case actions.FETCH_CHAT_MESSAGES: {
      let meta = { ...state.chatMeta };
      let messages = [...state.messages];

      if (action.payload.messages?.length) {
        messages = [...action.payload.messages];
      }

      if (action.payload.meta) {
        meta = { ...action.payload.meta };
      }

      return {
        ...state,
        messages: messages,
        chatMeta: meta,
        chatMessLoading: action.payload.loading,
        messageSearch: action.payload.search,
      };
    }
    case actions.CLEAR_CHAT_MESSAGES:
      return { ...state, messages: action.payload, chatMeta: {} };

    case actions.NEW_SOCKET_MESSAGE: {
      let messages = [...state.messages];
      let driverLoads = [...state.driverLoads];
      const currentFirstMessageID = messages[0]?.chat_id;
      const is_sender = action.is_sender;
      const chat_message = action.payload;
      const currentLoadIndex = _findIndexByChatID(
        driverLoads,
        chat_message?.chat_id
      );
      const isMessageDuplicate = _findIndexByID(messages, chat_message.id);

      // changing currently opened ChatScreen messages
      if (
        currentFirstMessageID === chat_message.chat_id &&
        isMessageDuplicate === -1
      ) {
        messages = [{ ...chat_message, is_sender }, ...messages]; // recent first, old last
      }

      // changing currently opened Driver-Loads (Chat Rooms)
      if (currentLoadIndex > -1) {
        if (is_sender) {
          // Current logged user sent this message
          driverLoads[currentLoadIndex] = {
            ...driverLoads[currentLoadIndex],
            chat_message: {
              ...chat_message,
              is_sender: true,
            },
          };
        } else {
          const current_member = driverLoads[currentLoadIndex].current_member;
          driverLoads[currentLoadIndex] = {
            ...driverLoads[currentLoadIndex],
            chat_message: {
              ...chat_message,
              is_sender: false,
            },
            current_member: {
              ...current_member,
              unread_count: current_member.unread_count + 1,
            },
          };
        }
      }

      return { ...state, messages, driverLoads };
    }

    case actions.READ_CHAT_MESSAGES: {
      let driverLoads = [...state.driverLoads];
      const currentLoadIndex = _findIndexByChatID(driverLoads, +action.chat_id);

      if (currentLoadIndex > -1)
        driverLoads[currentLoadIndex].current_member.unread_count = 0;

      return {
        ...state,
        chatRead: action.payload,
        driverLoads,
        chatBeingRead: action.loading,
      };
    }

    case actions.UPDATE_CHAT_BADGE: {
      let driverLoads = [...state.driverLoads];
      const currentLoadIndex = _findIndexByChatID(driverLoads, +action.chat_id);

      if (currentLoadIndex > -1)
        driverLoads[currentLoadIndex].current_member.unread_count = 0;

      return { ...state, driverLoads };
    }

    case actions.SOCKET_READ_MESSAGE: {
      let messages = [...state.messages];
      const msgIndex = messages.findIndex((m) => m?.id === action.payload);
      let driverLoads = [...state.driverLoads];
      const loadIndex = driverLoads.findIndex(
        (dl) => dl.chat_message.id === action.payload
      );

      if (msgIndex > -1) {
        messages[msgIndex].chat_message_status_id = 4;
        messages[msgIndex].chat_message_status.id = 4;
      }

      if (loadIndex > -1) {
        driverLoads[loadIndex].chat_message.chat_message_status_id = 4;
      }

      return { ...state, messages, driverLoads };
    }

    default:
      return state;
  }
};

export default chat;
