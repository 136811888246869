import {
  SET_LOADS_TABLE,
  SET_ACTIVE_LOADS_TABLE,
  SET_SOLD_LOADS_TABLE,
  SET_DRIVERS_ACTIVITY_TABLE,
  SET_INVOICES_TABLE,
  SET_MONTHLY_LOADS_TABLE,
} from "../types/tables";

const initialState = {
  loadsTable: "",
  monthlyLoadsTable: "",
  activeLoadsTable: "",
  soldLoadsTable: "",
  driversActivityTable: "",
  invoicesTable: "",
};

const tables = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADS_TABLE:
      return { ...state, loadsTable: action.tableData };
    case SET_MONTHLY_LOADS_TABLE:
      return { ...state, monthlyLoadsTable: action.tableData };
    case SET_ACTIVE_LOADS_TABLE:
      return { ...state, activeLoadsTable: action.tableData };
    case SET_SOLD_LOADS_TABLE:
      return { ...state, soldLoadsTable: action.tableData };
    case SET_DRIVERS_ACTIVITY_TABLE:
      return { ...state, driversActivityTable: action.tableData };
    case SET_INVOICES_TABLE:
      return { ...state, invoicesTable: action.tableData };
    default:
      return state;
  }
};

export default tables;
