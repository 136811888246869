import { httpGet } from "src/api";

export const getBrokers = (returnGetData) => {
  httpGet({
    url: "/admin/brokers",
    params: {
      paginate: 1,
      per_page: 10,
      broker_name: "",
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let brokers = setBrokers(data);

      returnGetData(brokers, meta, "brokers");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const setBrokers = (array) => {
  return array.map((item) => ({
    value: item.id,
    label:
      item.company_name +
      `${
        item.company && item.company.company_name
          ? " | | " + item.company.company_name
          : ""
      }`,
  }));
};

export const getCareers = (returnGetData) => {
  httpGet({
    url: "/admin/careers",
    params: {
      paginate: 1,
      page: 1,
      per_page: 10,
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let careers = setCarriersArray(data);
      returnGetData(careers, meta, "careers");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getUsers = (returnGetData, extraParams = {}) => {
  httpGet({
    url: "/admin/users",
    params: {
      paginate: 1,
      page: 1,
      per_page: 10,
      ...extraParams,
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let users = setDefaultReactSelectArray(data);
      returnGetData(users, meta, "users", extraParams);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getUsersWithCoWorker = (returnGetData, extraParams = {}) => {
  httpGet({
    url: "/admin/users",
    params: {
      paginate: 1,
      page: 1,
      per_page: 10,
      ...extraParams,
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let users = setUserWithCoWorker(data);
      returnGetData(users, meta, "users", extraParams);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCompanies = (returnGetData) => {
  httpGet({
    url: "/admin/companies",
    params: {
      paginate: 1,
      page: 1,
      per_page: 10,
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let companies = setCompanies(data);
      returnGetData(companies, meta, "companies");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getLocations = (returnGetData) => {
  httpGet({
    url: "/admin/locations",
    params: {
      paginate: 1,
      per_page: 10,
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let locations = setLocations(data);
      returnGetData(locations, meta, "locations");
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getTrucks = (returnGetData) => {
  httpGet({
    url: "/admin/trucks",
    params: {
      paginate: 1,
      per_page: 10,
      page: 1,
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let trucks = SetTrucksReactSelectValues(data);
      returnGetData(trucks, meta, "trucks");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getTrailers = (returnGetData) => {
  httpGet({
    url: "/admin/trailers",
    params: {
      paginate: 1,
      per_page: 10,
      page: 1,
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let trailers = SetTrailersReactSelectValues(data);
      returnGetData(trailers, meta, "trailers");
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getDrivers = (returnGetData, with_co_driver = false) => {
  httpGet({
    url: "/admin/drivers",
    params: {
      paginate: 1,
      per_page: 10,
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let drivers = setDriversArray(data);
      returnGetData(drivers, meta, "drivers");

      if (with_co_driver) {
        returnGetData(drivers, meta, "co_drivers");
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getGpsTracks = (returnGetData) => {
  httpGet({
    url: "admin/gps_tracks",
    params: {
      paginate: 1,
      per_page: 10,
      page: 1,
    },
  })
    .then((response) => {
      let data = response.data.data;
      let meta = response.data.meta;
      let gps_tracks = setGpsTracks(data);
      returnGetData(gps_tracks, meta, "gps_tracks");
    })
    .catch((error) => console.log(error));
};

export const setCompanies = (array) => {
  return array.map((item) => ({
    value: item.id,
    label: item.company_name,
  }));
};

export const setLocations = (array) => {
  return array.map((item) => ({
    ...item,
    value: item.id,
    type: item.type,
    label:
      item.company_name +
      " - " +
      item.address +
      " / " +
      item.state +
      " / " +
      item.city +
      ` (${
        !item.type === null ? "" : item?.type === 1 ? "Facility" : "Others"
      })`,
  }));
};

export const SetTrucksReactSelectValues = (array) => {
  return array.map((el) => ({
    value: el.id,
    label: `${el.unit_number + " " + el.vin}`,
  }));
};

export const SetTrailersReactSelectValues = (array) => {
  return array.map((el) => ({
    value: el.id,
    label: `${el.trailer_number}`,
  }));
};

export const setDriversArray = (array) => {
  return array.map((item) => ({
    value: item.id,
    label: item.first_name + " " + item.last_name,
  }));
};

export const switchSetUsersWithCoWorker = (array) => {
  return array.map((el) => ({
    value: el.id,
    label: el.name,
    shift_worker: el.shift_worker ? el.shift_worker.name : "",
  }));
};

export const setGpsTracks = (array) => {
  return array.map((el) => ({
    value: el?.id,
    label: `Company: ${el?.company}, id: ${el?.gps_id}`,
  }));
};

export const setUserWithCoWorker = (array) => {
  return array.map((el) => ({
    value: el?.id,
    label: `${el.name} ${
      el.shift_worker_id ? " / " + el.shift_worker.name : ""
    }`,
  }));
};

export const setCarriersArray = (array) => {
  return array.map((item) => ({
    value: item.id,
    label:
      item.name +
      (item.career_phone ? ` | ${item.career_phone}` : "") +
      (item.mcid ? ` | ${item.mcid}` : ""),
  }));
};

export const setDefaultReactSelectArray = (array) => {
  return array.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export const reactSelectRestructure = (field, data) => {
  switch (field) {
    case "brokers":
      return setBrokers(data);
    case "companies":
      return setCompanies(data);
    case "locations":
      return setLocations(data);
    case "trucks":
      return SetTrucksReactSelectValues(data);
    case "trailers":
      return SetTrailersReactSelectValues(data);
    case "drivers":
    case "co_drivers":
      return setDriversArray(data);
    case "gps_tracks":
      return setGpsTracks(data);
    case "users":
      return switchSetUsersWithCoWorker(data);
    case "careers":
      return setCarriersArray(data);
    default:
      return setDefaultReactSelectArray(data);
  }
};
