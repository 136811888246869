import { Notyf } from "notyf";
import { httpPost } from "src/api";
import Cookies from "universal-cookie";
let i = 0;
let timer;

export let debouncedRefreshLogin = async () => {
  if (i === 0) {
    i = 1;
    return await RefreshLogin();
  }
  clearTimeout(timer);
  i = 1;
  timer = setTimeout(() => {
    i = 0;
  }, 1000);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ data: "BACKEBD DATA" });
    }, 2000);
  });
};

export const RefreshLogin = async (
  text = "Token has expired, Connection is stabilized"
) => {
  const cookie = new Cookies();
  const notyf = new Notyf();
  if (cookie.get("refresh_token")) {
    if (!cookie.get("access_token")) {
      return await httpPost({
        url: "/auth/user/refresh",
      })
        .then((response) => {
          const today = new Date();
          const expireDate = new Date();
          const refreshExpireDate = new Date();

          expireDate.setTime(today.getTime() + response.data.expires_in * 800);
          refreshExpireDate.setTime(
            today.getTime() + response.data.refresh_expires_in * 900
          );

          cookie.set("access_token", response.data.access_token, {
            path: "/",
            expires: expireDate,
          });
          cookie.set("refresh_token", response.data.access_token, {
            path: "/",
            expires: refreshExpireDate,
          });
          notyf.success(text);
        })
        .catch((error) => {
          const notyf = new Notyf();
          console.log("refresh_token_error");
          cookie.remove("access_token");
          cookie.remove("refresh_token");
          window.location.href = "/#/login";
          notyf.error("Unauthorized");
        });
    } else return;
  } else {
    window.location.href = "/#/login";
    cookie.remove("access_token");
    // notyf.error("Unauthorized");
  }
};
