import { httpGet, httpPost } from "src/api";
import * as actions from "../types/notification";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const fetchNotifications =
  (type = "users", page = 1, per_page = 50) =>
  async (dispatch) => {
    const user_id = cookies.get("user_id");
    await httpGet({
      url: `admin/notifications`, // drivers/notifications
      params: {notifiable_id: user_id, paginate: 1, per_page, page, read_at: ''}
    })
      .then((res) => {
        let data = res.data?.data || []
        let dataParsed = data.map(item => ({...item, data: JSON.parse(item.data)}))
        dispatch({
          type: actions.FETCH_NOTIFICATIONS,
          payload: {notifications: dataParsed, meta: res.data?.meta || {}},
        });
      })
      .catch((e) => console.log(e));
  };

export const readNotification = (id) => async (dispatch) => {
  const type = +cookies.get("user_type_id") === 1 ? "driver" : "admin";
  await httpPost({
    url: `${type}/notifications/read/${id}`,
  })
    .then(() => {
      dispatch({
        type: actions.READ_NOTIFICATION,
        payload: id,
      });
    })
    .catch((e) => console.log(e));
};

export const readAllNotifications = () => async (dispatch) => {
  await httpPost({
    url: `admin/notifications/read`,
  })
    .then(() => {
      dispatch({
        type: actions.READ_ALL_NOTIFICATIONS,
      });
    })
    .catch((e) => console.log(e));
};
