export const FETCH_DRIVERS = "FETCH_DRIVERS";
export const CLEAR_DRIVERS = "CLEAR_DRIVERS";
export const FETCH_DRIVER_LOADS = "FETCH_DRIVER_LOADS";
export const CLEAR_CHAT_LOADS = "CLEAR_CHAT_LOADS";
export const FETCH_CHAT_MESSAGES = "FETCH_CHAT_MESSAGES";
export const CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const NEW_SOCKET_MESSAGE = "NEW_SOCKET_MESSAGE";
export const READ_CHAT_MESSAGES = "READ_CHAT_MESSAGES";
export const UPDATE_CHAT_BADGE = "UPDATE_CHAT_BADGE";
export const SOCKET_READ_MESSAGE = "SOCKET_READ_MESSAGE";
