import {
  FETCH_LOAD_BY_ID,
  SET_FETCHING,
  FETCH_LOADS_BY_STATUS,
  REMOVE_EDITING_LOAD,
  FETCH_LOADS_BY_SOLD_STATUS,
} from "../types/loads";

const initialState = {
  editingLoad: "",
  fetching: false,
  loads_status: "",
  loads_sold: "",
};

const loads = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOADS_BY_STATUS:
      return { ...state, loads_status: action.loads };
    case FETCH_LOADS_BY_SOLD_STATUS:
      return { ...state, loads_sold: action.loads };
    case FETCH_LOAD_BY_ID: {
      return { ...state, editingLoad: action.editingLoad };
    }
    case REMOVE_EDITING_LOAD: {
      return { ...state, editingLoad: "" };
    }
    case SET_FETCHING: {
      return { ...state, fetching: action.fetching };
    }
    default:
      return state;
  }
};

export default loads;
