import React, { useState, useEffect } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import Cookies from "universal-cookie";
import { getToken, onMessageListener } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { fetchNotifications } from "./store/actions/notification";
import { RefreshLogin } from "./utils/refreshAccessToken/refreshAccessToken";
import RegisterCarrier from "./views/pages/login/Register_carrier";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  const [loggedIn, setLoggedIn] = useState(true);
  const cookie = new Cookies();
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [mountTokenSet, setMountTokenSet] = useState(false);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const user_type_id = cookie.get("user_type_id");

  useEffect(() => {
    let token = cookie.get("access_token");
    let refreshToken = cookie.get("refresh_token");
    if (!token && !refreshToken) {
      setLoggedIn(false);
      setMountTokenSet(true);
    } else if (
      refreshToken &&
      !token &&
      !window.location.href.includes("/login")
    ) {
      RefreshLogin("Connection is recovered, welcome back")
        .then((res) => setMountTokenSet(true))
        .catch((err) => {
          console.log("app_refresh_token_error");
        });
    } else if (window.location.href.includes("/login")) {
      setMountTokenSet(true);
    } else if (token) {
      setMountTokenSet(true);
    }
  }, []);

  useEffect(() => {
    if (cookie.get("access_token")?.length) {
      getToken(setTokenFound);

      onMessageListener()
        .then((payload) => {
          setShow(true);
          setNotification({
            title: payload.notification.title,
            body: payload.notification.body,
          });
        })
        .catch((err) => console.log("failed: ", err));
    }
  }, [cookie]);

  useEffect(() => {
    if (user?.id) {
      dispatch(fetchNotifications(+user_type_id === 1 ? "drivers" : "users", 1, 50));
    }
  }, [user]);

  return (
    <>
      {/* <SocketWrapper> */}{" "}
      {/* Router will not work in this case, that's why use it in TheLayout */}
      <HashRouter>
        <React.Suspense fallback={loading}>
          {!loggedIn ? <Redirect to="/login" /> : null}
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/register_carrier"
              name="Register Page"
              render={(props) => <RegisterCarrier {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => (
                <TheLayout {...props} appMounted={mountTokenSet} />
              )}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
      {/* </SocketWrapper> */}
    </>
  );
};

export default App;
