import * as actions from "../types/map";

const initialState = {
  cashedWeatherInfo: [],
};

const mapWeather = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_WEATHER:
      return {
        ...state,
        cashedWeatherInfo: [...state.cashedWeatherInfo, action.payload],
      };

    default:
      return state;
  }
};

export default mapWeather;
