import { combineReducers } from "redux";
import chat from "./chat";
import user from "./user";
import sidebar from "./sidebar";
import loads from "./loads";
import tables from "./tables";
import drivers from "./drivers";
import locations from "./locations";
import loadExpense from "./loadExpense";
import notification from "./notification";
import mapWeather from "./mapWeather";

export const rootReducer = combineReducers({
  chat,
  sidebar,
  tables,
  user,
  loads,
  drivers,
  locations,
  loadExpense,
  notification,
  mapWeather,
});
