import {
  FETCH_DRIVERS,
  FETCH_DRIVERS_BY_ID,
  SET_DRIVERS,
} from "../types/drivers";

const initialState = {
  drivers: "",
  editingDriver: "",
};

const drivers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DRIVERS:
      return { ...state, drivers: action.drivers };
    case FETCH_DRIVERS_BY_ID:
      return { ...state, editingDriver: action.editingDriver };
    case SET_DRIVERS:
      return { ...state, drivers: action.drivers };
    default:
      return state;
  }
};

export default drivers;
