import moment from "moment";

export const getIsoFormat = (date) => {
  return new Date(date).toISOString().substr(0, 10);
};
export const timeShortener = (time, from = 0, to = 5) => {
  // returns HH:MM
  return time?.substr(from, to) || "";
};

export const getMaxMinDatesArray = (array, ...args) => {
  let maxMinDates = {};
  for (let i = 0; i < args.length; i++) {
    maxMinDates[`${args[i]}`] = { min: null, max: null };
  }
  array.map((item) => {
    for (let j = 0; j < args.length; j++) {
      if (
        maxMinDates[`${args[j]}`].min === null &&
        item[`${args[j]}`] !== null
      ) {
        maxMinDates[`${args[j]}`].min = item[`${args[j]}`];
      }
      if (maxMinDates[`${args[j]}`].max < item[`${args[j]}`]) {
        maxMinDates[`${args[j]}`].max = item[`${args[j]}`];
      } else if (
        maxMinDates[`${args[j]}`].min > item[`${args[j]}`] &&
        item[`${args[j]}`] !== null
      ) {
        maxMinDates[`${args[j]}`].min = item[`${args[j]}`];
      }
    }
  });
  return maxMinDates;
};

export const getTime = (e) => new Date(e.target.value).getTime();

export const getFahrenheit = (celsius = 0) =>
  celsius ? Math.round(celsius * 1.8 + 32) : "";

export const getWeekDay = (day) => {
  switch (day) {
    case 1:
      return "MON";
    case 2:
      return "TUE";
    case 3:
      return "WED";
    case 4:
      return "THU";
    case 5:
      return "FRI";
    case 6:
      return "SAT";
    case 0:
      return "SUN";

    default:
      return "";
  }
};

export const getLocationsTime = (currentObject, type) => {
  let item = currentObject[type];
  if (item !== null) {
    return (
      item.available_from_date &&
      (
        item.available_from_date.replace(
          /([0-9]*)\/([0-9]*)\/([0-9]*)/gm,
          "$2/$1"
        ) +
        " | " +
        `${item?.available_from_time}`
      ).concat(
        item.available_from_date !== null && item.available_to_date !== null
          ? item.available_from_date.concat(item.available_from_time) !==
            item.available_to_date.concat(item?.available_to_time)
            ? item.available_to_date &&
              item.available_to_date.replace(
                /([0-9]*)\/([0-9]*)\/([0-9]*)/gm,
                " - $2/$1"
              ) +
                " | " +
                `${item?.available_to_time}`
            : ""
          : item.available_to_date &&
              item.available_to_date.replace(
                /([0-9]*)\/([0-9]*)\/([0-9]*)/gm,
                " - $2/$1"
              ) +
                " | " +
                `${item?.available_to_time}`
      )
    );
  } else return "";
};

export const getLoadLocationTime = (currentObject, type) => {
  let item = currentObject[type];
  let returnItem = "";
  if (item !== null && item) {
    let available_from_day = moment(
      `${item.available_from_date}`,
      "YYYY-MM-DD"
    ).format("MM/DD");
    let available_to_day = moment(
      `${item.available_to_date}`,
      "YYYY-MM-DD"
    ).format("MM/DD");
    let available_from_time = moment(
      `${item.available_from_time}`,
      "HH:mm:ss"
    ).format("hh:mm a");
    let available_to_time = moment(
      `${item.available_to_time}`,
      "HH:mm:ss"
    ).format("hh:mm a");
    if (available_from_day === available_to_day) {
      returnItem = `${available_from_day} | ${
        available_from_time === available_to_time
          ? available_to_time
          : `${available_from_time} - ${available_to_time}`
      }`;
    } else if (available_to_time === available_from_time) {
      returnItem = `${available_from_day} - ${available_to_day} | ${available_to_time}`;
    } else {
      returnItem = `${available_from_day} | ${available_from_time} -| | |- ${available_to_day} | ${available_to_time}`;
    }
  }
  return returnItem;
};
// shipper_location || consignee_location
export const getLocationsTimeAndPlaces = (currentObject, type) => {
  let places = "";
  let time = "";
  if (currentObject) {
    places = `${
      currentObject &&
      currentObject[type] &&
      currentObject &&
      currentObject[type].location
        ? `${currentObject[type].location?.city}, ${currentObject[type].location?.state} | `
        : " "
    }`;
    time = `${
      currentObject &&
      currentObject[type] &&
      currentObject &&
      currentObject[type].location
        ? ` ${
            currentObject[type]?.available_from_date
              ? moment(
                  `${currentObject[type]?.available_from_date}`,
                  "YYYY-MM-DD"
                ).format("MM/DD")
              : ""
          }, ${moment(
            currentObject[type]?.available_from_time,
            "HH:mm:ss"
          ).format("hh:mm a")} ${
            currentObject[type]?.available_to_time !==
            currentObject[type]?.available_from_time
              ? " - " +
                moment(
                  currentObject[type]?.available_to_time,
                  "HH:mm:ss"
                ).format("hh:mm a")
              : ""
          } `
        : ""
    }`;
  }

  return places.concat(time);
};
// load_locations
export const getLoadLocations = (currentArray, type = "is_shipper") => {
  let places = "";
  let currentObject;
  if (type === "is_shipper")
    currentObject = currentArray.filter(
      (location) => +location.is_shipper === 1
    )[0];
  else {
    let currentObjectArray = currentArray.filter(
      (location) => +location.is_shipper !== 1
    );
    currentObject = currentObjectArray[currentObjectArray.length - 1];
  }
  if (currentObject) {
    places = `${
      currentObject && currentObject.location
        ? `${currentObject.location?.city}, ${currentObject.location?.state} `
        : " "
    }`;
  }
  return places;
};

// load_locations
export const getConsigneeDate = (currentArray) => {
  // console.log('currentArray', currentArray)
  let currentObjectArray = currentArray.filter(
    (location) => +location.is_shipper !== 1
  );
  let currentObject = currentObjectArray[currentObjectArray.length - 1];
  return (
    currentObject &&
    currentObject.available_to_date &&
    currentObject.available_to_date.replace(
      /([0-9]*)-([0-9]*)-([0-9]*)/gm,
      " $2/$3"
    )
  );
};

export const getArrayWeeks = (dates = "", callbackFunction = "") => {
  if (dates !== null) {
    let start =
      dates && dates.start ? new Date(dates.start) : new Date("2021/03/29");
    let end = dates && dates.end ? new Date(dates.end) : new Date();
    let weeks = [];
    let countofWeek = 0;
    for (let cur = start; cur <= end; cur.setDate(cur.getDate() + 7)) {
      countofWeek++;
      let currentWeekStart = moment(cur);
      weeks.push({
        period_start: currentWeekStart.format("MM/DD/yyyy"),
        value: countofWeek,
        id: countofWeek,
        active: false,
        period_end: currentWeekStart.add("days", 6).format("MM/DD/yyyy"),
      });
    }
    if (callbackFunction) {
      callbackFunction(
        `[${weeks[weeks.length - 1].period_start}, ${
          weeks[weeks.length - 1].period_end
        }]`
      );
    }
    return weeks;
  }
};
