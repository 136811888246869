import * as actions from "../types/loadExpense";

const initialState = {
  loadExpenses: [],
};

const loadExpenses = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_LOAD_EXPENSES:
      return { ...state, loadExpenses: action.payload };
    case actions.DELETE_LOAD_EXPENSE:
      return { ...state, loadExpenses: action.payload };
    case actions.CREATE_LOAD_EXPENSE:
      return { ...state, loadExpenses: action.payload };

    default:
      return state;
  }
};

export default loadExpenses;
