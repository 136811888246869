import { useState, useEffect } from "react";
import { httpGet } from "src/api";
import { reactSelectRestructure } from "./getDataFunctions";

export default function useDebounce(value, delay) {
  const [debounceValue, setDebounceValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debounceValue;
}

export const debounce = (fn, ms) => {
  let timeout;
  return function () {
    const fnCall = () => {
      fn.apply(this, arguments);
    };
    clearTimeout(timeout);
    timeout = setTimeout(fnCall, ms);
  };
};

export function debounceScroll(method, delay) {
  clearTimeout(method._tId);
  method._tId = setTimeout(function () {
    method();
  }, delay);
}

const FilterReactSelect = (
  url,
  field,
  name,
  event,
  returnFunction,
  extraParams = {}
) => {
  // change per page here
  httpGet({
    url,
    params: {
      [name]: event,
      per_page: 10,
      paginate: 1,
      page: 1,
      ...extraParams,
    },
  })
    .then((response) => {
      let data = reactSelectRestructure(field, response.data.data);
      let last_page = response.data.meta?.last_page;
      returnFunction(data, field, last_page, event, response);
    })
    .catch((error) => {
      console.log(error);
    });
};

// debounce time is here

export const SearchByDebounce = debounce(
  (url, field, name, event, returnFunction, extraParams) => {
    FilterReactSelect(url, field, name, event, returnFunction, extraParams);
  },
  600
);

export const prepareThrottlingScroll = (
  url,
  currentPage,
  name,
  searchInput,
  callback,
  field = "",
  extraParams = {}
) => {
  // change per page here
  httpGet({
    url,
    params: {
      paginate: 1,
      per_page: 10,
      page: currentPage + 1,
      [name]: searchInput,
      ...extraParams,
    },
  })
    .then((response) => {
      let data = reactSelectRestructure(field, response.data.data);
      callback(response, data);
    })
    .catch((err) => console.log("err scroll", err));
};

const FilterTableFetch = (
  url,
  searchParams,
  page,
  per_page = 100,
  returnFunction
) => {
  // change per page here
  httpGet({
    url,
    params: {
      ...searchParams,
      per_page,
      paginate: 1,
      page,
    },
  })
    .then((response) => {
      returnFunction(response);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const TableSearchByDebounce = debounce(
  (url, searchParams, page, per_page, returnFunction) => {
    FilterTableFetch(url, searchParams, page, per_page, returnFunction);
  },
  600
);
