import React, { Component, useState } from "react";
import { Notyf } from "notyf";
import { httpPost } from "../../../api";
import "notyf/notyf.min.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

const RegisterCarrier = () => {
  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
    email: "",
    age: "",
  });

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const formSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div
      style={{ backgroundImage: "url(/bgs/login-logistics.jpg)" }}
      className="c-app c-default-layout flex-row align-items-center login-wrapper"
    >
      <div className="overlay-blur"></div>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8" className="card-group-wrap">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <form onSubmit={formSubmit}>
                    <h1 className="mb-0">
                      <strong>Register</strong>
                    </h1>
                    <p className="text-muted">Sign up</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        name="user_name"
                        value={formData.user_name}
                        onChange={handleFormChange}
                        placeholder="Username"
                        autoComplete="username"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleFormChange}
                        autoComplete="current-email"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-bell" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleFormChange}
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-list" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="number"
                        placeholder="Age"
                        name="age"
                        value={formData.age}
                        onChange={handleFormChange}
                        autoComplete="current-age"
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton type="submit" color="primary" className="px-4">
                          Sign up
                        </CButton>
                      </CCol>
                    </CRow>
                  </form>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5 d-md-down-none"
                style={{ width: "44%" }}
              >
                <CCardBody className="text-center">
                  <div>
                    <img
                      src="/octopus-white.png"
                      alt="Octopus KAIS Logistics"
                      className="img-fluid w-50"
                    />
                  </div>
                </CCardBody>
                <CCardBody className="text-center">
                  <div>
                    <h2>Register Page</h2>
                    <p>Octopus's Admin Panel</p>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default RegisterCarrier;
