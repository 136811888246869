import * as actions from "../types/notification";

const initialState = {
  notifications: [],
  notifications_properties: {}
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_NOTIFICATIONS:
      return { ...state, notifications: [...state.notifications, ...action.payload.notifications], notifications_properties: action.payload.meta };

    case actions.READ_NOTIFICATION:
      let sortedNotifications = [...state.notifications];

      sortedNotifications = sortedNotifications.filter(
        (n) => n.id !== action.payload
      );

      return { ...state, notifications: sortedNotifications };

    case actions.CLEAR_NOTIFICATIONS:
      return { ...state, notifications: [] };

    case actions.READ_ALL_NOTIFICATIONS:
      return { ...state, notifications: [] };

    default:
      return state;
  }
};

export default notification;
