import { FETCH_USER, SET_USER_SETTINGS } from "../types/user";

const initialState = {
  user: {},
  user_settings: "",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        user: action.payload,
        user_settings:
          action.payload && action.payload.user_settings
            ? action.payload.user_settings
            : "",
      };
    case SET_USER_SETTINGS:
      return {
        ...state,
        user_settings:
          action.payload && action.payload.user_settings
            ? action.payload.user_settings
            : "",
      };
    default:
      return state;
  }
};

export default user;
